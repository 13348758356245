import React from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';
import {graphql, useStaticQuery } from 'gatsby';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


export default function() {
    const data = useStaticQuery(query)
    const {allSanityCxDesignService:{
        nodes:cxd
    }} = data
    return (
        <div className="cx-design-services services-section">  
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <div className="services-section-head">
                            <h2 className="services-section-title">UX and Design Services</h2> 
                            <span className="services-section-desc">Strategically creating, delivering, and optimizing rich and personalized digital experiences that solve unmet customer needs and deliver brand value.</span>
                            <Button href="/services/cx-design/" >Learn more <img src={require('../../assets/images/next_service.png').default} alt="arrow right" /></Button>
                        </div>
                    </Col>
                </Row>
                <Tabs>
                    <div className="cx-design-tabs services-section-tabs">
                        <Row>
                            <Col xs={12} md={12} lg={6} className="tabs-list-ctn">
                                <TabList>
                                    {cxd.map((cxd) =>{
                                        return <Tab>
                                        <h3>{cxd.title}</h3>
                                        <p>{cxd.paragraph}</p>
                                    </Tab>
                                    })}
                                </TabList>
                            </Col>
                            <Col xs={12} md={12} lg={6} className="gif-img-ctn">
                                {cxd.map((cxd) =>{
                                    return <TabPanel>
                                    <video loop={true} poste={cxd.image.asset.url} autoPlay="autoplay" muted playsInline>
                                        <source src={cxd.webm.asset.url} type="video/webm"></source>
                                    </video>
                                </TabPanel>
                                })}
                            </Col>
                        </Row> 
                    </div>
                </Tabs>
            </Container>
        </div>
    )
}

export const query = graphql`
{
    allSanityCxDesignService(sort: {fields: _createdAt}) {
      nodes {
        title
        paragraph
        image {
          asset {
            url
          }
        }
        webm {
          asset {
            url
          }
        }
      }
    }
}
`