import React from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';
import {graphql, useStaticQuery } from 'gatsby';

export default function() {
    const data = useStaticQuery(query)
    const {allSanityTechAndEngineeringService:{
        nodes:tae
    }} = data
    return (
        <div className="platform-engineering-service services-section">  
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <div className="services-section-head">
                            <h2 className="services-section-title">Technology and Engineering Services</h2> 
                            <span className="services-section-desc">Applying cutting-edge technologies to build the foundation for growth. We build and run complex platforms that give brands digital products that stand out.</span>
                            <Button href="/services/technology-engineering/" >Learn more <img src={require('../../assets/images/next_service.png').default} alt="arrow right" /></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="services-section-cards">
                    <Col xs={12} md={12} lg={6} className="left-col">
                        {tae.slice(0, 1).map((tae) =>{
                            return <div className='services-section-cards-card full-card'>
                                <h3>{tae.title}</h3>
                                <p>{tae.paragraph}</p>
                                <img src={tae.image.asset.url} alt={tae.title} />
                                <h4>{tae.subTitle}</h4>
                                <Button href={tae.btnHref}>{tae.btnText}</Button>
                                <p>{tae.secondParagraph}</p>
                            </div>
                        })}
                    </Col>
                    <Col xs={12} md={12} lg={6} className="right-col">
                        <div className="half-cards-ctn">
                            {tae.slice(1, 2).map((tae) =>{
                                return  <div className='services-section-cards-card half-card less-space'>
                                    <img src={tae.image.asset.url} alt={tae.title} className='space-bottom' />
                                    <h3>{tae.title}</h3>
                                    <p>{tae.paragraph}</p>
                                </div>
                            })}
                            {tae.slice(2).map((tae) =>{
                                return <div className='services-section-cards-card half-card less-space'>
                                    <h3>{tae.title}</h3>
                                    <p>{tae.paragraph}</p>
                                    <img src={tae.image.asset.url} alt={tae.title} className='space-top width-half bottom'/>
                                </div>
                            })}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export const query = graphql`
{
    allSanityTechAndEngineeringService(sort: {fields: _createdAt}) {
      nodes {
        title
        paragraph
        image {
          asset {
            url
          }
        }
        subTitle
        btnText
        btnHref
        secondParagraph
      }
    }
}
`