import React from 'react'
import Layout from "../components/Layout"
import { Seo } from '../components/Seo'
import ServicesBanner from '../components/services/servicesBanner'
import CxDesign from '../components/services/cx-design'
import PlatformEngineering from '../components/services/platform-engineering';
import ECommerce from '../components/services/e-commerce';
import Experimentation from '../components/services/experimentation';
import AiSolutions from '../components/services/ai-solutions'

export default function() {
    return (
    <Layout>
        <Seo 
          title="Services - Sogody" 
          description="Deep dive into tech and strategy updates of the Martech industry and beyond." 
        />
        <div className='services-page'>
          <ServicesBanner
           heading='Engineering digital experiences to have clients convert without pitching'
           description='A diverse range of skills that embraces a specific set of fundamentals, talent and knowledge expertise in each area.'
           contactText='Get in touch'
           />
          <CxDesign />
          <PlatformEngineering />
          <ECommerce />
          <Experimentation />
          <AiSolutions />
        </div>
    </Layout>
    )
}
