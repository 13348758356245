import React from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';
import {graphql, useStaticQuery } from 'gatsby';

export default function() {
    const data = useStaticQuery(query)
    const {allSanityExperimentationService:{
        nodes: exp 
    }} = data
    return (
        <div className="experimentation-service services-section">  
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <div className="services-section-head">
                            <h2 className="services-section-title">Experimentation Services</h2> 
                            <span className="services-section-desc">Adding data science and technology smarts to marketing and product teams. Making sure that brands always deliver relevant experiences, while also saving time and money.</span>
                            <Button href="/services/experimentation/" >Learn more <img src={require('../../assets/images/next_service.png').default} alt="arrow right" /></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="services-section-cards">
                    <Col xs={12} md={12} lg={6} className="left-col">
                        <div className="half-cards-ctn">
                            {exp.slice(0,2).map((exp) => { 
                                return  <div className='services-section-cards-card half-card'>
                                    <h3>{exp.title}</h3>
                                    <p>{exp.paragraph}</p>
                                    <img src={exp.image.asset.url} alt={exp.title} className='space-top' />
                                </div>
                            })}
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="right-col">
                        {exp.slice(2).map((exp) => { 
                        return <div className='services-section-cards-card full-card'>
                                <h3>{exp.title}</h3>
                                <p>{exp.paragraph}</p>
                                <img src={exp.image.asset.url} alt={exp.title} className="corner-img"/>
                            </div>
                        })}   
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export const query = graphql`
{
    allSanityExperimentationService(sort: {fields: _createdAt}) {
      nodes {
        title
        paragraph
        image {
          asset {
            url
          }
        }
      }
    }
} 
`

