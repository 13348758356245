import React from 'react';
import { Row, Col, Container,Button} from 'react-bootstrap';
import {graphql, useStaticQuery } from 'gatsby';


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export default function() {
    const data = useStaticQuery(query)
    const {allSanityAiSolutionsService:{
        nodes:ec
    }} = data
    return (
        <div className="ai-services services-section">  
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <div  iv className="services-section-head">
                            <h2 className="services-section-title">AI Solutions</h2> 
                            <span className="services-section-desc">Crafting a range of real-world use cases with our AI-powered offerings. Engineered for diverse industries and scenarios, demonstrating the versatility, effectiveness, and adaptability of our AI innovations.</span>
                            <Button href="/services/ai-solutions/" >Learn more <img src={require('../../assets/images/next_service.png').default} alt="arrow right" /></Button>
                        </div>
                    </Col>
                </Row>
                <Tabs>
                    <div className="ai-solutions-tabs services-section-tabs">
                        <Row>
                            <Col xs={12} md={12} lg={6} className="tabs-list-ctn">
                                <TabList>
                                    {ec.map((ec) =>{
                                        return <Tab>
                                            <h3>{ec.title}</h3>
                                            <p>{ec.paragraph}</p>
                                        </Tab>
                                    })} 
                                </TabList>
                            </Col>
                            <Col xs={12} md={12} lg={6} className="gif-img-ctn">
                                {ec.map((ec) =>{
                                    return <TabPanel>
                                        <video loop={true} psote={ec.image.asset.url} autoPlay="autoplay" muted playsInline>
                                            <source src={ec.webm.asset.url} type="video/mp4"></source>
                                        </video>
                                    </TabPanel>
                                })}
                            </Col>
                        </Row> 
                    </div>
                </Tabs>
            </Container>
        </div>
    )
}

export const query = graphql`
{
    allSanityAiSolutionsService(sort: {fields: _createdAt}) {
      nodes {
        title
        paragraph
        image {
          asset {
            url
          }
        }
        webm {
          asset {
            url
          }
        }
      }
    }
}
`